.pi-overlay-height {
    height: 12rem;
}

.pi-text-overlay-background {
    background-color: rgba(0, 0, 0, 0.4);
}

.pi-cursor {
    cursor: pointer;
}