.pi-custom-container {
    min-height: 100vh;
}

.pi-scroll {
    max-height: calc(100vh - 3.3125rem);
}

@media (max-width: 992px) {
    .pi-sidebar-flex {
        flex: 0 0 100%;
    }

    .pi-container-flex {
        flex: 0 0 100%;
    }
}

@media (min-width: 992px) {
    .pi-sidebar-flex {
        flex: 0 0 20%;
    }

    .pi-container-flex {
        flex: 0 0 78%;
    }
}

@media (min-width: 1100px) {
    .pi-sidebar-flex {
        flex: 0 0 20%;
    }

    .pi-container-flex {
        flex: 0 0 80%;
    }
}

.pi-flex-container-full {
    flex: 0 0 100%;
}
