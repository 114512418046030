@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Noto+Serif+JP:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Railway', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Serif JP', sans-serif;
  letter-spacing: -1px;
}

button, a {
  font-family: 'Noto Serif JP', sans-serif;
}

p {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* Custom Container */
.container {
  max-width: 1500px !important;
}

/* Font Families */

.pi-montserrat
{
  font-family: 'Montserrat', sans-serif !important;
  letter-spacing: -1px;
}

.pi-noto-serif-jp {
  font-family: 'Noto Serif JP', sans-serif !important;
}

/* Font Weights */

.pi-weight-500 {
  font-weight: 500;
}

.pi-weight-600 {
  font-weight: 600;
}

/* Font Size Classes */

.pi-font-size-8 {
  font-size: var(--pi-font-size-8) !important;
}

.pi-font-size-10 {
  font-size: var(--pi-font-size-10) !important;
}

.pi-font-size-12 {
  font-size: var(--pi-font-size-12) !important;
}

.pi-font-size-14 {
  font-size: var(--pi-font-size-14) !important;
}

.pi-font-size-16 {
  font-size: var(--pi-font-size-16) !important;
}

.pi-font-size-18 {
  font-size: var(--pi-font-size-18) !important;
}

.pi-font-size-20 {
  font-size: var(--pi-font-size-20) !important;
}

.pi-font-size-22 {
  font-size: var(--pi-font-size-22) !important;
}

.pi-font-size-24 {
  font-size: var(--pi-font-size-24) !important;
}

.pi-font-size-28 {
  font-size: var(--pi-font-size-28) !important;
}

.pi-font-size-32 {
  font-size: var(--pi-font-size-32) !important;
}

.pi-font-size-36 {
  font-size: var(--pi-font-size-36) !important;
}

.pi-font-size-42 {
  font-size: var(--pi-font-size-42) !important;
}

.pi-font-size-48 {
  font-size: var(--pi-font-size-48) !important;
}

.pi-font-size-56 {
  font-size: var(--pi-font-size-56) !important;
}

.pi-font-size-64 {
  font-size: var(--pi-font-size-64) !important;
}

.pi-font-size-72 {
  font-size: var(--pi-font-size-72) !important;
}

.pi-font-size-80 {
  font-size: var(--pi-font-size-80) !important;
}

.pi-font-size-120 {
  font-size: var(--pi-font-size-120) !important;
}

.pi-font-size-160 {
  font-size: var(--pi-font-size-160) !important;
}

/* Background Color Classes */

.pi-bg-color-primary {
  background-color: var(--pi-primary-color) !important;
}

.pi-bg-color-secondary {
  background-color: var(--pi-secondary-color) !important;
}

.pi-bg-color-secondary-tint {
  background-color: var(--pi-secondary-tint-color) !important;
}

.pi-bg-color-tertiary {
  background-color: var(--pi-tertiary-color) !important;
}

.pi-bg-color-accent-primary {
  background-color: var(--pi-accent-primary) !important;
}

.pi-bg-color-primary-gray {
  background-color: var(--pi-primary-gray-color) !important;
}

.pi-bg-color-secondary-gray {
  background-color: var(--pi-secondary-gray-color) !important;
}

.pi-bg-color-medium-gray {
  background-color: var(--pi-medium-gray-color) !important;
}

.pi-bg-color-light-gray {
  background-color: var(--pi-light-gray-color) !important;
}

.pi-bg-color-dark {
  background-color: var(--pi-dark-color) !important;
}

/* Color Classes */

.pi-color-primary {
  color: var(--pi-primary-color) !important;
}

.pi-color-secondary {
  color: var(--pi-secondary-color) !important;
}

.pi-color-secondary-tint {
  color: var(--pi-secondary-tint-color) !important;
}

.pi-color-tertiary {
  color: var(--pi-tertiary-color) !important;
}

.pi-color-accent-primary {
  color: var(--pi-accent-primary) !important;
}

.pi-color-dark {
  color: var(--pi-dark-color) !important;
}

.pi-color-primary-gray {
  color: var(--pi-primary-gray-color) !important;
}

.pi-color-secondary-gray {
  color: var(--pi-secondary-gray-color) !important;
}

.pi-color-medium-gray {
  color: var(--pi-medium-gray-color) !important;
}

.pi-color-light-gray {
  color: var(--pi-light-gray-color) !important;
}

/* Button Classes */

.pi-primary-btn {
  background-color: var(--pi-primary-color) !important;
  color: white;
  border: 1px solid transparent !important;
}

.pi-primary-btn:hover, .pi-primary-btn:focus {
  background-color: transparent !important;
  color: var(--pi-primary-color) !important;
  border: 1px solid var(--pi-primary-color) !important;
}

.pi-primary-btn:active, .pi-primary-btn.active {
  background-color: transparent !important;
  color: var(--pi-primary-color) !important;
  border: 1px solid var(--pi-primary-color) !important;
}

.pi-primary-outline-btn {
  background-color: transparent !important;
  color: var(--pi-primary-color) !important;
  border-color: var(--pi-primary-color) !important;
}

.pi-primary-outline-btn:hover, .pi-primary-outline-btn:focus {
  background-color: var(--pi-primary-color) !important;
  color: white !important;
}

.pi-primary-outline-btn:active, .pi-primary-outline-btn.active {
  background-color: var(--pi-primary-color) !important;
  color: white !important;
}

.pi-secondary-btn {
  background-color: var(--pi-secondary-color) !important;
  border: none !important;
}

.pi-secondary-btn:hover, .pi-secondary-btn:focus {
  background-color: var(--pi-secondary-color) !important;
  opacity: 0.8 !important;
  border: none !important;
}

.pi-secondary-btn:active, .pi-secondary-btn.active {
  background-color: var(--pi-secondary-color) !important;
  border: none !important;
}

.pi-tertiary-btn {
  background-color: var(--pi-tertiary-color) !important;
  border: none !important;
}

.pi-tertiary-btn:hover, .pi-tertiary-btn:focus {
  background-color: var(--pi-tertiary-color) !important;
  opacity: 0.8 !important;
  border: none !important;
}

.pi-tertiary-btn:active, .pi-tertiary-btn.active {
  background-color: var(--pi-tertiary-color) !important;
  border: none !important;
}

/* Link Classes */

.pi-link-primary {
  color: var(--pi-primary-color) !important;
}

@media (min-width: 768px) {
  .pi-link-primary:hover {
    color: var(--pi-primary-gray-color) !important;
  }
}

.pi-link-secondary {
  color: var(--pi-primary-gray-color) !important;
}

@media (min-width: 768px) {
  .pi-link-secondary:hover {
    color: var(--pi-primary-color) !important;
  }
}

/* Border Classes */

.pi-border-top {
  border-top: 1px solid var(--pi-secondary-gray-color);
}

.pi-border-color-primary-gray {
  border-color: var(--pi-primary-gray-color) !important;
}

.pi-border-color-primary {
  border-color: var(--pi-primary-color) !important;
}

:root {
  --pi-primary-color: #0190E8;
  --pi-secondary-color: #072963;
  --pi-secondary-tint-color: #09347F;
  --pi-tertiary-color: #96ACD9;
  --pi-accent-primary: #009D8A;
  --pi-dark-color: #1F314F;
  --pi-primary-gray-color: #5F6872;
  --pi-secondary-gray-color: #CED4DA;
  --pi-medium-gray-color: #ABB5C0;
  --pi-light-gray-color: #F4F5F6;
  --pi-font-size-8: 0.5rem;
  --pi-font-size-10: 0.675rem;
  --pi-font-size-12: 0.75rem;
  --pi-font-size-14: 0.875rem;
  --pi-font-size-16: 1rem;
  --pi-font-size-18: 1.125rem;
  --pi-font-size-20: 1.25rem;
  --pi-font-size-22: 1.375rem;
  --pi-font-size-24: 1.5rem;
  --pi-font-size-28: 1.75rem;
  --pi-font-size-32: 2rem;
  --pi-font-size-36: 2.25rem;
  --pi-font-size-42: 2.625rem;
  --pi-font-size-48: 3rem;
  --pi-font-size-56: 3.5rem;
  --pi-font-size-64: 4rem;
  --pi-font-size-72: 4.5rem;
  --pi-font-size-80: 5rem;
  --pi-font-size-120: 7.5rem;
  --pi-font-size-160: 10rem;
}

