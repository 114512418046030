.pi-image {
    max-height: 16rem;
}

.pi-timeline-container {
    max-height: 20rem;
    overflow-y: scroll;
}

.pi-line-container {
    width: 100%;
    height: 10rem;
}
