.mailchimp {
    border: 15px solid white;
    border-radius: 3px;
    position: relative;
    background: white;
    z-index: 2;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    margin: auto;
}

.mailchimp-input {
    font-size: 15px;
    outline: none;
    border: none;
    width: 100%;
    padding: 15px;
}

@media only screen and (max-width: 576px) {
    .w-sm-100 {
        width: 100%;
    }
}
