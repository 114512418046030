.pdf-container {
    margin:auto;
    width: 100%;
    max-height: 80vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-page > canvas {
    max-width: 100%;
    height: auto !important;
}

@media (min-width: 768px) {
    .pi-responsive-border {
        border-right: 1px solid rgba(0,0,0,.125);
    }
}