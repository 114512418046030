.pi-background {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.03'%3E%3Cpolygon fill='%23d7e3e9' points='800 100 0 200 0 800 1600 800 1600 200'/%3E%3Cpolygon fill='%23aec7d3' points='800 200 0 400 0 800 1600 800 1600 400'/%3E%3Cpolygon fill='%2386acbd' points='800 300 0 600 0 800 1600 800 1600 600'/%3E%3Cpolygon fill='%235d90a7' points='1600 800 800 400 0 800'/%3E%3Cpolygon fill='%23466e81' points='1280 800 800 500 320 800'/%3E%3Cpolygon fill='%23304c58' points='533.3 800 1066.7 800 800 600'/%3E%3Cpolygon fill='%231a2930' points='684.1 800 914.3 800 800 700'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
}

.pi-circle-icon {
    flex: 0 0 3rem;
    height: 3rem;
}

.pi-process-container {
    overflow-x: scroll;
    height: 550px;
}
