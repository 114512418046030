@media (max-width: 768px) {
    .pi-border {
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
}

@media (min-width: 768px) {
    .pi-border {
        border-right: 1px solid rgba(0,0,0,.1);
    }
}