.pi-circle {
    border-radius: 10px/10px;
    border: solid 1px #000000;
    width: 10px;
    height: 10px;
}

.pi-line {
    width: 1px;
    background-color: #000000;
}

.pi-line-height {
    height: calc(100% - 10px);
}

.pi-update-click {
    cursor: pointer;
}