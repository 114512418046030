.navbar {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

@media only screen and (min-width: 970px) {
    .navbar {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}