.pi-chart {
    height: 25rem;
    width: 100%;
}

.pi-bump-chart {
    height: 12rem;
    width: 100%;
}

.pi-note-position {
    bottom: 0;
}