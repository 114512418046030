.pi-primary-btn {
    background-color: var(--pi-primary-color);
    border: none;
}

.pi-primary-btn:hover, .pi-primary-btn:focus {
    background-color: var(--pi-primary-color);
    opacity: 0.8;
    border: none;
}

.pi-primary-btn:active, .pi-primary-btn.active {
    background-color: var(--pi-primary-color) !important;
    border: none;
}